// @font-face {
//     font-family: "Roboto";
//     src: url(/fonts/Roboto-Regular.ttf);
// }

/* HDDS Theme Colors */
$backgroundColor: #fff;
$softGray: #eee;
$black35: #353535;
$acidWashGray: #e0e4eb;
$hdOrange: #f96302;
$deepHDOrange: #E05A01;
$b1Blue: #b1bace;
$deepB1Blue: #9fa7b9;
$errorRed: #cf2a27;
$focusBlue: #9fc5f8;
$disabledGray: #696969;
$jeanBlue: #556689;
$deepJeanBlue: #4C5B7B; /*one shade darker than jeanBlue*/

/* Misc color variables */
$gridTextShadow: #000;
$gridTextColor: #000;
$gridDataTextColor: #000;
$gridHeaderTextColor: #fff;
$gridHeaderBorderColor: #d3d3d3;
$gridOutsideBorderColor: $black35;

/* KENDO VARIABLE RESETS */

/* Grid Variables */


@import '~@progress/kendo-theme-default/dist/all.scss';

.h-group-header {
    background-color: #000;
    color:$gridHeaderTextColor;
    border-left: 1px solid $gridHeaderBorderColor;
    border-top: 1px solid $gridHeaderBorderColor;
    width: 100%;
    column-span: all;
}

.h-grid-icon {
    color: $jeanBlue;
}

.k-header{
    color: $backgroundColor;
}

.k-grid{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border: 0px;
}

.k-grid-header .k-header > .k-link {
    padding: 7px;
    font-weight: bold;
    font-size: 1em;
    text-shadow: 1px 1px 0px $gridTextShadow;
}

div.k-grid-header
{
   padding: 0 !important;
   border-bottom: 1px solid $gridOutsideBorderColor;
   border-right:solid $gridOutsideBorderColor 1px;
   border-radius:6px 6px 0 0;
   -moz-border-radius:6px 6px 0 0;
   position: -webkit-sticky;
   position: sticky;
   z-index: 5;
   overflow-y: auto;
}

div.k-grid-content {
    overflow-x: hidden;
    overflow-y: auto;
    border-right:solid $gridOutsideBorderColor 1px;
    border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    position: -webkit-sticky;
}

.k-grid-header-wrap > table {
    background-color: $jeanBlue;
    border-collapse:separate;
    border-left:solid $gridOutsideBorderColor 1px;
    border-right:solid $gridOutsideBorderColor 1px;
    border-top:solid $gridOutsideBorderColor 1px;
    border-radius:6px 6px 0 0;
    -moz-border-radius:6px 6px 0 0;
}


.k-grid-container table{
    border-collapse:separate;
    border-left:solid $gridOutsideBorderColor 1px;
    border-right:solid $gridOutsideBorderColor 1px;
    border-bottom:solid $gridOutsideBorderColor 1px;
    border-radius:0 0 6px 6px;
    -moz-border-radius:0 0 6px 6px;
}


.k-grid-header-wrap > table > thead > tr > th {
    border-bottom: 1px solid $gridHeaderBorderColor;
    border-right: 1px solid $gridHeaderBorderColor;
}

.k-grid-header-wrap > table > thead > tr > th:first-child {
    border-left: 0px;
}

.k-grid-header-wrap > table > thead > tr > th:last-child {
    border-right: 0px;

}

.k-grid-table tr > td {
    padding: 2px;
    background-color: $backgroundColor;
    border-bottom: 1px solid $acidWashGray;
    border-right: 1px solid $acidWashGray;
    font-size: .9em;
    color: $gridTextColor;
}

.k-grid-table tr:last-child > td:first-child {
    border-radius:0 0 0 6px;
}

.k-grid-table tr:last-child > td:last-child {
    border-radius:0 0 6px 0;
}

.k-grid-table tr:hover > td {
    cursor: pointer;
    background-color: $softGray;
}

.k-grid-table tr:last-child > td {
    border-bottom: 1px solid $acidWashGray;
}

.k-grid-table tr > td > button{
    padding: 0px;
}

.k-icon {
    color: $hdOrange;
}

.k-button-icon {
    color: $hdOrange;
}

.k-dropdown-operator {
    margin-right: 3px;
}

.k-grid-table td {
    white-space: nowrap;
} 

  
